import uuidv4 from "uuid/v4";

class Animal {
  animalUuid: string = uuidv4();
  tagId: string = "";
  tagType: "no-tag" | "plain" | "allflex" | "dimssa" | "dimssa-manual";
  tagColourNumber: number; //if tagType is dimssa-manual otherwise null
  feedlot: string = ""; //name of feedlot
  mass: number = -1;
  uploadStatus: string = ""; //"sending" | "failedToSend" | "receivedByServer" | "receivedBy3rdParty"
  numberInQueue: number = -1; //1-based index of animal's number in queue, 1 for 1st, 2 for 2nd, etc...
  timestamp: number;
  barcode?: string = "";

}

export { Animal };
