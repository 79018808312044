


















































import { Component, Prop, Vue } from "vue-property-decorator";

let uid = 0;

@Component
export default class DimssaTable extends Vue {
  @Prop() headers!: Array<any>;
  @Prop() items!: Array<any>;
  @Prop() isModifiedTable: boolean = true;

  name: String = "DimssaTable";
  tableHeaderId: string = "table-header-" + uid++;
  tableBodyId: string = "table-body-" + uid++;
  tableBodyWidth: number = 1;

  onTableBodyResize() {
    // if (document && document.getElementById(this.tableBodyId)) {
    // 	let el = document.getElementById(this.tableBodyId);
    // 	if (el) {
    // 		this.tableBodyWidth = el.clientWidth;
    // 	}
    // 	else {
    // 		throw new Error(`dimssa-table error: could not find table body with id: ${this.tableHeaderId}`);
    // 	}
    // }
  }
}
