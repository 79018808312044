/* eslint-disable */
import * as d3 from "d3";

var svg: any;
var elem;
var line: d3.Line<[number, number]>;

export function initializeChart() {

	d3.select("#scale-graph-svg").selectAll('*').remove();

	svg = d3.select('#scale-graph-svg')
		.attr("width", "100%")
		.attr("height", "100%");

	elem = document.getElementById('scale-graph-svg');

	if (!elem) {
		console.log("scale-graph-svg not found");
		return;
	}


	var svgWidth = elem.clientWidth;
	var svgHeight = elem.clientHeight;

	// console.log('svgHeight: ' + svgHeight)
	// console.log('svgWidth: ' + svgWidth)

	var margin = { top: 32, right: 32, bottom: 32, left: 32 };

	var width = svgWidth - margin.left - margin.right;
	var height = svgHeight - margin.top - margin.bottom;

	//console.log(svg)

	var g = svg.append("g")
		.attr("transform", "translate(" + margin.left + "," + margin.top + ")");

	var x = d3.scaleLinear().rangeRound([0, width]);
	var y = d3.scaleLinear().rangeRound([height, 0]);

	//x.domain(d3.extent(data, function(d) { return d.date }));
	x.domain([0, 49]);
	//y.domain(d3.extent(data, function(d) { return d.value }));
	y.domain([0, 800]);

	line = d3.line()
		.curve(d3.curveBasis)
		.x(function (d) { return x(+d[0]); })
		.y(function (d) { return y(+d[1]); });

	g.append("g")
		.attr("transform", "translate(0," + height + ")")
		.call(d3.axisBottom(x)).selectAll("text").remove();

	g.append("g")
		.call(d3.axisLeft(y));
}

export function drawChart(data: any, drawHorizontalLine: Boolean) {
	//var svgWidth = 600, svgHeight = 400;
	// var margin = { top: 20, right: 20, bottom: 30, left: 50 };
	// var width = svgWidth - margin.left - margin.right;
	// var height = svgHeight - margin.top - margin.bottom;

	//svg.selectAll("g path").remove()
	svg.select("#scale-graph-line").remove();
	svg.select("#horizontal-graph-line").remove();

	var g = svg.select("g");

	g.append("path")
		.datum(data)
		.attr("id", "scale-graph-line")
		.attr("fill", "none")
		.attr("stroke", "steelblue")
		.attr("stroke-linejoin", "round")
		.attr("stroke-linecap", "round")
		.attr("stroke-width", 3.0)
		.attr("d", line);

	if (drawHorizontalLine) {
		g.append("path")
			//.datum([{x: 0, mass: data[data.length-1].mass}, {x: 49, mass: data[data.length-1].mass}])
			.datum([[0, data[data.length - 1][1]], [49, data[data.length - 1][1]]])
			.attr("id", "horizontal-graph-line")
			.attr("fill", "none")
			.attr("stroke", "lime")
			.attr("stroke-linejoin", "round")
			.attr("stroke-linecap", "round")
			.attr("stroke-width", 3.0)
			.attr("d", line);
	}
}