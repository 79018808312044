







































































































































































































































































































































import EarTag from "../components/shared/EarTag.vue";
import DimssaButton from "../components/shared/dimssa-button.vue";
import DimssaTable from "../components/shared/dimssa-table.vue";

import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Animal } from "../animal";
import { Batch } from "../batch";
import lodash from "lodash";
import axios from "axios";

import { configData } from "../config";

var JsBarcode = require("jsbarcode");

@Component({
  components: {
    DimssaButton,
    EarTag,
    DimssaTable,
  },
})
export default class Orca extends Vue {
  currentEartagFull = "";
  currentBarcode = "";
  isBusySaving = false;
  okPopupVisible = false;
  okPopupMessage = "";
  unsentAnimalRecords: any = {};
  candidateBatch: any = null; //the selected batch in list of batches, selection still needs to be confirmed
  fetchedBatches: any = [];
  batchListFetchState = "notBusy"; //"notBusy" | "busy" | "succeeded" | "failed"
  orcaEnabled = true;
  statusMessage = "";
  isNewBatchDialogVisible = false;
  isClearTagDialogVisible = false;
  firstTagScanned = false;
  isUHFScanMode = true;
  isNoTagDialogVisible = false;
  noTagForcedInput = "";
  tagItems = [
    {
      text: "No Tag",
      value: "No Tag",
      disabled: false,
    },
    {
      text: "Manual",
      value: "Manual",
      disabled: false,
    },
  ];
  barcodeItems = [
    {
      text: "No Barcode",
      value: "No Barcode",
      disabled: false,
    },
  ];
  batchTableHeaders = [
    {
      text: "Number",
      align: "center",
      sortable: false,
      value: "string",
    },
    {
      text: "Description",
      align: "center",
      sortable: false,
      value: "string",
    },
    {
      text: "Total",
      align: "center",
      sortable: false,
      value: "number",
    },
    {
      text: "Completed",
      align: "center",
      sortable: false,
      value: "number",
    },
  ];
  server = {
    orcaAddress: this.$store.state.orcaAddress,
    address: this.$store.state.hardwareAddress,
    portHttp: configData.server.portHttp,
    portOrcaWebSocket: configData.server.portOrcaWebSocket,
  };

  mounted() {
    this.getFeedlotInfo();
    this.$vuetify.theme.dark = this.$store.getters["dark"]();
    this.$store.dispatch("restoreSession");
    this.connectOrcaWebSocket();
    // this.onClearTagConfirmed();
  }

  tableKey = 0;
  forcekeyUpdate() {
    this.tableKey += 1;
  }
  setBarcodeScanMode() {
    let url = "https://" + this.server.orcaAddress + ":" + this.server.portHttp + configData.restApiCalls.D2Barcode;
    axios
      .get(url, { timeout: 5000 })
      .then((response: any) => {
        this.isUHFScanMode = false;
        this.firstTagScanned = false;
        // app.$store.commit("SET_SGLN", response.data);
      })
      .catch((error: any) => {
        console.log("barcode error: " + error);
      })
      .finally(() => {
        this.isUHFScanMode = false;
        this.firstTagScanned = false;
      });
  }

  setUHFScanMode() {
    let url = "https://" + this.server.orcaAddress + ":" + this.server.portHttp + configData.restApiCalls.sgln;
    axios
      .get(url, { timeout: 5000 })
      .then((response: any) => {
        this.firstTagScanned = false;
        this.isUHFScanMode = true;
      })
      .catch((error: any) => {
        console.log("barcode error: " + error);
      })
      .finally(() => {
        this.firstTagScanned = false;
        this.isUHFScanMode = true;
      });
  }

  getFeedlotInfo() {
    console.log("getting feedlot info");
    let app = this;
    axios
      .get(configData.restApiCalls.feedlots, { timeout: 5000 })
      .then((response) => {
        //console.log(response);
        app.$store.commit("SET_FEEDLOT_INFO", response.data);
        console.log("feedlot info successfully retrieved");
        console.log("getting colour info");
        axios
          .get(configData.restApiCalls.colours, { timeout: 5000 })
          .then((response) => {
            app.$store.commit("SET_COLOUR_INFO", response.data);
            console.log("colour info successfully retrieved");
            console.log("getting sgln");
            //TODO: environment check for http and https:
            let url = "https://" + this.server.orcaAddress + ":" + this.server.portHttp + configData.restApiCalls.sgln;
            axios
              .get(url, { timeout: 5000 })
              .then((response) => {
                console.log("sgln: " + response.data);
                app.$store.commit("SET_SGLN", response.data);
              })
              .catch((error) => {
                console.log("sgln error: " + error);
              });
          })
          .catch((error) => {
            console.log("colour info error: " + error);
          });
      })
      .catch((error) => {
        console.log("feedlot info error: " + error);
      })
      .finally(() => {});
  }

  options = { fontSize: 40, textPosition: "top", height: 22, fontOptions: "bold", font: "verdana" };

  initializeBarcode(serial: String) {
    if (serial === undefined) {
      console.log("no barcode to initialize- hiding");
      document.getElementById("barcode").style.visibility = "hidden";
    } else {
      console.log(`initializing barcode to ` + serial);
      document.getElementById("barcode").style.visibility = "visible";
      JsBarcode("#barcode", serial, { ...this.options });
    }
  }

  checkDuplicateScanTag(tagUrn: String) {
    let app = this;
    axios.defaults.timeout = 10000;
    //lamda function has to be used here, using function() syntax results in a different 'this'
    //TODO: Environment check for http and https
    axios
      .get("https://" + app.server.address + ":" + app.server.portHttp + configData.restApiCalls.isSlaughtered + "/" + tagUrn)
      .then((response2) => {
        console.log("response: " + response2);
        let isSlaughtered = response2.data;
        if (!isSlaughtered) {
          this.$store.commit("tagCapture/SET_TAG_ID", tagUrn);
          this.$store.commit("tagCapture/SET_TAG_TYPE", "dimssa");
          this.$store.commit("tagCapture/SET_STATE", "success_dimssa_tag_scanned");
          //this.currentEartagFull = this.$store.state.tagCapture.tagId;
          this.currentEartagFull = this.getVisualFriendlyTag(this.$store.state.tagCapture.tagId, this.$store.state.tagCapture.tagType);

          this.$store.commit("tagCapture/SET_TAG_COLOUR", this.$store.getters["getTagColour"](this.$store.state.tagCapture.tagId));
          app.statusMessage = "";
          this.setBarcodeScanMode();

          //app.scanTagState = "scanned";
        } else {
          app.okPopupVisible = true;
          let s = `Error: duplicated detected ${this.getVisualFriendlyTag(tagUrn, "dimssa")}`;
          app.okPopupMessage = s;
          app.statusMessage = s;
          //app.scanTagState = "failed";
          this.$store.commit("tagCapture/SET_TAG_ID", "");
          this.currentEartagFull = "";
          this.$store.commit("tagCapture/SET_STATE", "empty_tag");
          this.$store.commit("tagCapture/FLAG_ERROR", s);
          this.firstTagScanned = false;
          //TODO: maybe display to user this is a duplicate -> might be done somewhere else
        }
      })
      .catch((error) => {
        console.log("error: " + error);
        let s = "Error checking duplicate";
        app.statusMessage = s;
        //app.scanTagState = "failed";
        this.$store.commit("tagCapture/SET_STATE", "empty_tag");
        this.$store.commit("tagCapture/FLAG_ERROR", s);
        return false;
      });
    return false;
  }

  connectOrcaWebSocket() {
    if (!this.orcaEnabled) return;
    //TODO: Environment detection for ws and wss
    let ws = new WebSocket("wss://" + this.server.orcaAddress + ":" + this.server.portOrcaWebSocket);
    let app = this;
    (ws.onerror = function (event) {
      console.log("orca websocket error");
    }),
      (ws.onmessage = (message) => {
        if (!this.firstTagScanned) {
          this.firstTagScanned = true;

          if (this.isUHFScanMode) {
            console.log("Orca: " + message.data);
            if (this.$store.state.tagCapture.tagCaptureState === "empty_tag") {
              this.$store.commit("tagCapture/SET_TAG_ID", message.data);
              this.$store.commit("tagCapture/SET_STATE", "success_dimssa_tag_scanned");
              this.$store.commit("tagCapture/SET_TAG_TYPE", "dimssa");
              //this.currentEartagFull = this.$store.state.tagCapture.tagId;
              this.currentEartagFull = this.getVisualFriendlyTag(this.$store.state.tagCapture.tagId, this.$store.state.tagCapture.tagType);
              console.log(this.currentEartagFull);
              this.checkDuplicateScanTag(this.$store.state.tagCapture.tagId);
            }
          } else {
            if (message.data.startsWith("urn:")) {
              //ignore because it is a uhf tag coming through
            } else {
              console.log("Orca: " + message.data);
              //TODO: fill in barcode
              this.$store.commit("tagCapture/SET_BARCODE", message.data);
              this.$store.commit("tagCapture/SET_STATE", "success_barcode_scanned");
              this.currentBarcode = message.data;
              this.forcekeyUpdate();
              this.initializeBarcode(this.currentBarcode);
              this.firstTagScanned = false;
            }
          }
        }
      });
    ws.onclose = () => {
      // Try to reconnect in 5 seconds
      //TODO: Environment detection for ws and wss
      lodash.debounce(() => {
        app.connectOrcaWebSocket();
      }, 5000)();
    };
  }
  get manualState() {
    //if manual entry
    let tagCaptureState = this.$store.state.tagCapture.tagCaptureState;
    let tagCaptureReadyToSave = !["ready_for_manual_input", "manual_input_received"].includes(tagCaptureState);
    if (tagCaptureReadyToSave) return true;
    else return false;
  }
  get tagTypeVisualFriendly() {
    return this.getVisualFriendlyTagType(this.$store.state.tagCapture.tagType);
  }
  getGcpFromSgtin(sgtin: String) {
    return sgtin ? sgtin.split(".")[1] : null;
  }
  getColourModeFromSgtin(sgtin: String) {
    if (!this.$store.state.feedlotInfo) return null;
    let gcp = this.getGcpFromSgtin(sgtin);
    if (!gcp) return null;

    let feedlot = lodash.find(this.$store.state.feedlotInfo, (f) => {
      return f.GCP === gcp;
    });

    return feedlot ? feedlot.ColorMode : null;
  }
  getFeedlotNameFromSgtin(sgtin: String) {
    if (!this.$store.state.feedlotInfo) return null;
    let gcp = this.getGcpFromSgtin(sgtin);
    if (!gcp) return null;

    let feedlot = lodash.find(this.$store.state.feedlotInfo, (f) => {
      return f.GCP === gcp;
    });

    return feedlot ? feedlot.Name : null;
  }
  get currentBatchDescription() {
    return this.currentBatch ? this.currentBatch.description : "";
  }
  onClickDisabledNewBatchButton() {
    if (this.newBatchButtonState === "disabled") {
      this.statusMessage = "Can not start new batch until all animals on the list have been uploaded";
    }
  }
  get newBatchButtonState() {
    if (this.currentBatch) {
      //check if any animals have not been uploaded yet
      let unsentAnimals = this.currentBatch.animals.filter((animal: any) => animal.uploadStatus === "sending" || animal.uploadStatus === "failedToSend");
      if (unsentAnimals.length > 0) {
        return "disabled";
      }
    }
    return "ready";
  }
  onBatchSelected(batch: any) {
    this.candidateBatch = new Batch();
    this.candidateBatch.batchNumber = batch.BatchNumber;
    this.candidateBatch.description = batch.Description;
    this.candidateBatch.totalQuantity = batch.TotalQty;
    this.candidateBatch.completedQuantity = batch.ComplQty;
    this.candidateBatch.animals = lodash.map(batch.Animals, (o) => {
      let a = new Animal();
      a.animalUuid = o.Uuid;
      a.mass = o.LiveMass;
      a.tagId = o.TagType === "dimssa" ? o.RfidTag : o.Eartag;

      a.tagType = o.TagType;

      a.numberInQueue = o.AnimalNo;
      a.uploadStatus = "receivedByServer";
      return a;
      //TODO: timestamp if needed
      // o.Uuid = animal.animalUuid,
      // o.BatchNo = state.currentBatch.batchNumber,
      // o.AnimalNo = animal.numberInQueue,
      // o.Eartag = animal.tagId,
      // o.LiveMass = animal.mass,
      // o.GrossMass = animal.mass,
      // o.Timestamp = "/Date("+animal.timestamp+"+0200)/"
    });
  }
  async onBatchSelectionConfirmed() {
    this.isNewBatchDialogVisible = false;
    //this.currentBatch = this.candidateBatch;
    await this.$store.dispatch("setCurrentBatch", this.candidateBatch);
    this.currentEartagFull = this.$store.state.tagCapture.tagId;
  }
  onBatchSelectionCancelled() {
    this.isNewBatchDialogVisible = false;
    this.candidateBatch = null;
  }

  getVisualFriendlyTag(tagId: any, tagType: any) {
    if (tagType === "dimssa") {
      let colourMode = this.getColourModeFromSgtin(tagId);
      if (colourMode === 1)
        //"single-colour"
        return tagId[36] + tagId.slice(-5);
      else if (colourMode === 0)
        //"multi-colour"
        return tagId.slice(-5);
      //else if multi colour mode then just take last 5;
    } else if (tagType === "allflex") {
      return tagId.slice(-12);
    } else if (tagType === "dimssa-manual") {
      //return tagId.slice(1);
      return tagId;
    } else {
      return tagId;
    }
  }

  onClearTagConfirmed() {
    this.isClearTagDialogVisible = false;
    this.$store.commit("tagCapture/SET_TAG_ID", "");
    this.currentEartagFull = ""; //this.$store.state.tagCapture.tagId;
    this.$store.commit("tagCapture/SET_STATE", "empty_tag");
    this.currentBarcode = "";
    this.initializeBarcode(undefined);
    this.setUHFScanMode();
    this.forcekeyUpdate();
  }

  onClearTagCancelled() {
    this.isClearTagDialogVisible = false;
  }

  get isManualDialogVisible() {
    return this.$store.state.tagCapture.tagCaptureState === "tag_type_selection";
  }

  get isManualTagEntry() {
    let tagCaptureState = this.$store.state.tagCapture.tagCaptureState;
    return tagCaptureState === "ready_for_manual_input" || tagCaptureState === "manual_input_received";
  }
  get isColourSelectionDialogVisible() {
    return this.$store.state.tagCapture.tagCaptureState === "tag_colour_selection";
  }
  get saveButtonState() {
    let tagCaptureState = this.$store.state.tagCapture.tagCaptureState;
    let tagCaptureReadyToSave = !["empty_tag"].includes(tagCaptureState);
    //TODO CONFIG: make this settable.
    if (this.currentBatch && tagCaptureReadyToSave) {
      //this mode doesn't care for mass capture
      if (this.currentBarcode && this.currentEartagFull && this.currentBarcode.length > 0 && this.currentEartagFull.length > 0) return "ready";
    }
    return "disabled";
  }

  onManualTagConfirmed() {
    //if dimssa-manual chosen then ask user to select colour
    //else do the usual
    (this.$refs["manual-tag-item-group"] as any).internalLazyValue = undefined;
    let tagType = this.$store.state.tagCapture.tagType;
    if (tagType === "dimssa-manual") {
      this.$store.commit("tagCapture/SET_STATE", "tag_colour_selection");
    } else {
      this.$store.commit("tagCapture/SET_STATE", "ready_for_manual_input");
      //     this.$refs["eartag-input-field"].disabled = false;
    }

    this.currentEartagFull = "";
    this.setBarcodeScanMode();
  }

  onManualTagCancelled() {
    this.$store.commit("tagCapture/SET_STATE", "empty_tag");
    //  (this.$refs["manual-tag-item-group"] as any).internalLazyValue = undefined;
  }
  onTagColourConfirmed() {
    //  (this.$refs["tag-colour-item-group"] as any).internalLazyValue = undefined;
    this.$store.commit("tagCapture/SET_STATE", "ready_for_manual_input");
    //this.$refs["eartag-input-field"].disabled = false;
    //this.$refs["eartag-input-field"].focus();
  }
  onTagColourCancelled() {
    //this.$refs["tag-colour-item-group"].internalLazyValue = undefined;
    this.$store.commit("tagCapture/SET_STATE", "empty_tag");
  }

  onClickManualTagTypeItem(tagType: any, isSelected: any) {
    console.log("onClickManualTagTypeItem: " + tagType + " " + isSelected);
    if (isSelected) {
      this.$store.commit("tagCapture/SET_TAG_TYPE", tagType);
    } else {
      this.$store.commit("tagCapture/SET_TAG_TYPE", null);
    }
  }
  onClickTagColourItem(tagColour: any, isSelected: boolean) {
    if (isSelected) {
      this.$store.commit("tagCapture/SET_TAG_COLOUR", tagColour);
    } else {
      this.$store.commit("tagCapture/SET_TAG_COLOUR", null);
    }
  }
  onEartagInputChanged(event: any) {
    console.log("eartag_change: " + event);
    if (event?.value === "Manual") {
      this.onClickManualButton(event);
    } else if (event?.value === "No Tag") {
      this.onClickNoTagButton(event);
    }
    let tagCaptureState = this.$store.state.tagCapture.tagCaptureState;
    if (tagCaptureState === "ready_for_manual_input" || tagCaptureState === "manual_input_received") {
      this.currentEartagFull = event;
      if (tagCaptureState === "ready_for_manual_input" && this.currentEartagFull.length > 0) {
        this.$store.commit("tagCapture/SET_STATE", "manual_input_received");
      } else if (tagCaptureState === "manual_input_received" && this.currentEartagFull.length === 0) {
        this.$store.commit("tagCapture/SET_STATE", "ready_for_manual_input");
      }
      console.log(`we are settting tag id to ${event}`);
      this.$store.commit("tagCapture/SET_TAG_ID", event);
    }
    if (tagCaptureState === "no_tag") {
      //
    }

    // if (document.getElementById("eartag-input-field").value === "") {
    //this.isManualTagEntry = false;
    //  }
  }
  onBarcodeInputChanged(event: any) {
    console.log("Barcode Input Changed" + JSON.stringify(event));
    this.$store.commit("tagCapture/SET_BARCODE", event);
    this.$store.commit("tagCapture/SET_STATE", "success_barcode_scanned");
    this.currentBarcode = event;
    this.initializeBarcode(this.currentBarcode);
  }

  onNoBarcodeSelected(event: any) {
    console.log("No Barcode Selected" + JSON.stringify(event));
    if (event && event.value === "No Barcode") {
      this.$store.commit("tagCapture/SET_BARCODE", event.value);
      this.$store.commit("tagCapture/SET_STATE", "no_barcode_tag");
      this.currentBarcode = event.value;
      this.initializeBarcode(this.currentBarcode);
    }
  }

  async onClickSaveButton(event: any) {
    this.statusMessage = "";
    //add animal data to table
    //set icon to progress
    //alert(uuidv4())

    // let animalRecord = {
    //   batchId: "123456",
    //   animalUid: uuidv4(),
    //   numberInBatch: 0,
    //   eartagId: "123456",
    //   urn: "",
    //   liveMass: 888,
    // }
    if (this.isBusySaving) {
      console.log("avoiding saving more than once");
      return;
    }

    this.isBusySaving = true;

    let animalRecord = new Animal();
    animalRecord.timestamp = new Date().getTime();
    animalRecord.tagColourNumber = this.$store.state.tagCapture.tagType === "dimssa-manual" ? this.$store.state.tagCapture.tagColour.Number : null;
    animalRecord.tagType = this.$store.state.tagCapture.tagType;
    animalRecord.tagId = this.$store.state.tagCapture.tagId;
    animalRecord.feedlot = "feedlot";
    animalRecord.barcode = this.$store.state.tagCapture.barcode;
    //animalRecord.mass = this.massCaptureEnabled && this.captureMassState === "captured" ? this.currentMass : 0;
    animalRecord.uploadStatus = "failedToSend";
    animalRecord.numberInQueue = this.currentBatch.completedQuantity + 1;
    this.statusMessage = "";

    this.unsentAnimalRecords[animalRecord.animalUuid] = animalRecord;
    //this.currentBatch.animals.push(animalRecord);
    await this.$store.dispatch("saveAnimal", animalRecord);

    let isFinished = this.$store.state.currentBatch.completedQuantity === this.$store.state.currentBatch.totalQuantity;
    let tagType = this.$store.state.tagCapture.tagType;

    if (isFinished) {
      this.okPopupVisible = true;
      this.okPopupMessage = "Batch Complete, select new batch";
    }

    //clear captured tag and mass
    this.$store.commit("tagCapture/SET_TAG_ID", "");
    this.currentEartagFull = this.$store.state.tagCapture.tagId;
    if (!isFinished && tagType === "plain") {
      this.$store.commit("tagCapture/SET_STATE", "ready_for_manual_input");
      //this.$refs["eartag-input-field"].focus();
    } else if (isFinished || tagType !== "plain") {
      this.$store.commit("tagCapture/SET_STATE", "empty_tag");
    }

    //TODO: Need another way
    this.currentBarcode = "";
    this.initializeBarcode(undefined);
    this.setUHFScanMode();
    this.forcekeyUpdate();
    //this.scanTagState = "notScanning";

    //this.captureMassState = "notCapturing";
    //this.isManualTagEntry = false;

    // let o = {
    //   BatchNo: "Test Batch",
    //   AnimalNo: 1,
    //   Eartag: "W00123",
    //   Eartag2: null,
    //   RfidTag: "",
    //   LiveMass: 334,
    //   HookMass: 0,
    //   GrossMass: 334,
    //   Feedlot: null,
    //   Timestamp: "/Date(1543558980000+0200)/",
    //   Barcode: ""
    // }

    this.isBusySaving = false;
  }
  get currentBatch() {
    return this.$store.state.currentBatch;
  }
  onClickClearButton(event: any) {
    this.isClearTagDialogVisible = true;
  }
  onClickManualButton(event: any) {
    this.$store.commit("tagCapture/SET_STATE", "tag_type_selection");
    console.log("onClickManualButton");
  }
  onClickDisabledSaveButton() {
    this.statusMessage = "Save button disabled: ";

    let tagCaptureState = this.$store.state.tagCapture.tagCaptureState;
    let tagCaptureReadyToSave = ["success_barcode_scanned"].includes(tagCaptureState);

    if (!tagCaptureReadyToSave) {
      this.statusMessage += "tag must first be captured";
    } else if (!this.currentBatch) {
      this.statusMessage += "batch must first be selected";
    }
  }
  get noTagButtonState() {
    let tagCaptureState = this.$store.state.tagCapture.tagCaptureState;
    if (this.currentBatch && this.currentBatch.completedQuantity >= this.currentBatch.totalQuantity) {
      return "disabled";
    } else if (tagCaptureState === "empty_tag") {
      return "ready";
    } else {
      return "disabled";
    }
  }
  get uhfTagButtonState() {
    if (this.currentBatch && this.currentBatch.completedQuantity >= this.currentBatch.totalQuantity) {
      return "disabled";
    } else if (this.isUHFScanMode) {
      return "ready";
    } else return "disabled";
  }
  get barcodeTagButtonState() {
    if (this.currentBatch && this.currentBatch.completedQuantity >= this.currentBatch.totalQuantity) {
      return "disabled";
    } else if (this.isUHFScanMode) {
      return "disabled";
    } else if (this.currentBarcode && this.currentBarcode.length > 0) {
      return "disabled";
    } else return "ready";
  }
  get clearButtonState() {
    if (this.currentEartagFull && this.currentEartagFull.length > 0) {
      return "ready";
    } else if (this.currentBarcode && this.currentBarcode.length > 0) {
      return "ready";
    } else {
      return "disabled";
    }
  }

  get manualButtonState() {
    let tagCaptureState = this.$store.state.tagCapture.tagCaptureState;
    if (this.currentBatch && this.currentBatch.completedQuantity >= this.currentBatch.totalQuantity) {
      return "disabled";
    } else if (tagCaptureState === "empty_tag") {
      return "ready";
    } else {
      return "disabled";
    }
  }

  animalTableHeaders = [
    {
      text: "Number",
      align: "center",
      width: "20px",
      sortable: false,
      value: "number",
    },
    {
      text: "Eartag/ID",
      align: "center",
      sortable: false,
      value: "eartagId",
    },
    {
      text: "Barcode",
      align: "center",
      sortable: false,
      value: "barcode",
    },
    {
      text: "Feedlot",
      align: "center",
      sortable: false,
      value: "feedlot",
    },
    {
      text: "Status",
      align: "center",
      sortable: false,
      value: "status",
    },
  ];

  get animalTableItems() {
    let ret: any[] = [];
    if (!this.currentBatch) return ret;

    this.currentBatch.animals.forEach((animalRecord: any) => {
      //console.log(this.$store.getters["getTagColour"](animalRecord.tagId).Name);
      ret.push({
        number: animalRecord.numberInQueue,
        eartagId: this.getVisualFriendlyTag(animalRecord.tagId, animalRecord.tagType),
        feedlot: animalRecord.tagType === "dimssa" ? this.getFeedlotNameFromSgtin(animalRecord.tagId) : "N/A",
        tagType: this.getVisualFriendlyTagType(animalRecord.tagType),
        liveMass: animalRecord.mass > 0 ? animalRecord.mass : "N/A",
        status: animalRecord.uploadStatus,
        colour: this.$store.getters["getTagColour"](animalRecord.tagId) ? this.$store.getters["getTagColour"](animalRecord.tagId).Name : undefined,
        barcode: animalRecord.barcode,
      });
    });
    return ret.reverse();
  }
  getVisualFriendlyTagType(tagType: string) {
    if (!tagType) {
      return null;
    }
    switch (tagType) {
      case "no-tag": {
        return "No tag";
      }
      case "plain": {
        return "Plain";
      }
      case "allflex": {
        return "Allflex";
      }
      case "allflex-manual": {
        return "Allflex Manual";
      }
      case "dimssa": {
        return "Gigalot";
      }
      case "dimssa-manual": {
        return "Gigalot Manual";
      }
    }
  }
  onClickNoTagButton(event: any) {
    this.onNoTagConfirmed();
  }
  onNoTagConfirmed() {
    //Popup are you sure?
    //
    this.isNoTagDialogVisible = false;
    this.$store.commit("tagCapture/SET_STATE", "no_tag");
    this.$store.commit("tagCapture/SET_TAG_TYPE", "no-tag");
    let numNoTags;
    if (this.$store.state.currentBatch) {
      let savedAnimals = this.$store.state.currentBatch.animals;
      numNoTags = savedAnimals.filter((animal: any) => animal.tagType === "no-tag").length;
    } else {
      numNoTags = 0;
    }
    this.$store.commit("tagCapture/SET_TAG_ID", `NO TAG ${numNoTags + 1}`);
    this.currentEartagFull = this.$store.state.tagCapture.tagId;
    this.statusMessage = "NO TAG";
    this.setBarcodeScanMode();
  }
  onNoTagCancelled() {
    this.isNoTagDialogVisible = false;
  }
  onClickNewBatchButton(tagType: any) {
    //this.selectedTagType = tagType;
    this.isNewBatchDialogVisible = true;
    this.batchListFetchState = "busy";
    this.fetchedBatches = [];
    console.log("https://" + this.server.address + ":" + this.server.portHttp + configData.restApiCalls.getBatches);
    //TODO: do environment chech for http and https
    axios
      .get("https://" + this.server.address + ":" + this.server.portHttp + configData.restApiCalls.getBatches, { timeout: 20000 })
      .then((response) => {
        //this.selectedTagType = tagType;
        //this.$store.commit("SET_SELECTED_TAG_TYPE", tagType);
        this.fetchedBatches = response.data.filter((i: any) => i !== null);
        this.batchListFetchState = "succeeded";
        if (this.fetchedBatches.length > 0) {
          this.batchListFetchState = "succeeded";
        } else {
          console.log("empty list of batches returned");
          this.statusMessage = "empty list of batches returned";
          this.batchListFetchState = "failed";
        }
      })
      .catch((error) => {
        this.batchListFetchState = "failed";
      });
  }
}
