import { Module, ActionContext } from "vuex";
import ColourInfo from "@/colour-info";

type TagCaptureState =
  | "empty_tag"
  | "scanning_dimssa_tag"
  | "success_dimssa_tag_scanned"
  | "allflex_tag_scanned"
  | "no_tag"
  | "tag_type_selection"
  | "tag_colour_selection"
  | "ready_for_manual_input"
  | "manual_input_received"
  | "scanning_barcode"
  | "no_barcode_tag"
  | "manual_barcode_entered"
  | "success_barcode_scanned";

type TagType = "no-tag" | "plain" | "allflex" | "allflex-manual" | "dimssa" | "dimssa-manual"; //dimssa-manual is a dimssa tag entered manually

class TagCaptureData {
  tagCaptureState: TagCaptureState = "empty_tag";
  tagType: TagType = null;
  tagColour: ColourInfo = null;
  tagId: string = "";
  error: boolean = false;
  errorMessage: string = "";
  barcode: string = "";
}

class TagCapture implements Module<TagCaptureData, any> {
  namespaced: boolean = true;
  state: TagCaptureData = new TagCaptureData();
  mutations = {
    SET_STATE(state: TagCaptureData, payload: TagCaptureState) {
      state.tagCaptureState = payload;
      state.error = false;
      state.errorMessage = "";

      if (state.tagCaptureState === "empty_tag") {
        state.tagId = "";
        state.tagType = null;
        state.tagColour = null;
        console.log(`setting colour to null colour`);
      }
    },
    SET_TAG_ID(state: TagCaptureData, payload: string) {
      state.tagId = payload;
    },
    SET_BARCODE(state: TagCaptureData, payload: string) {
      state.barcode = payload;
    },
    SET_TAG_TYPE(state: TagCaptureData, payload: TagType) {
      state.tagType = payload;
    },
    FLAG_ERROR(state: TagCaptureData, payload: string) {
      state.error = true;
      state.errorMessage = payload;
    },
    SET_TAG_COLOUR(state: TagCaptureData, payload: ColourInfo) {
      console.log(`setting colour to ${payload ? payload.Name : "null colour"}`);
      state.tagColour = payload;
    }
  };
  actions = {
    restoreSession(context: ActionContext<TagCaptureData, any>,) {
      if (context.state.tagCaptureState === "tag_type_selection") {
        //do this to sync up the UI with state.
        context.commit("SET_TAG_TYPE", null);
      }
    }
  };
}

const tagCapture: TagCapture = new TagCapture();

export default tagCapture;
