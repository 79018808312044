import uuidv4 from "uuid/v4";
import { Animal } from "./animal";

class Batch {
  constructor() { }

  batchUuid: string = uuidv4();
  animals: Animal[] = new Array();
  batchNumber: string = "";
  description: string = "";
  totalQuantity: number = 0;
  completedQuantity: number = 0;
}

export { Batch };
