//import idb, { DB } from "idb";
import * as idb from "idb";
import { Batch } from "./batch";

class DataManager {
  constructor() { }

  private _dbPromise: Promise<idb.DB>;

  initDb(): Promise<idb.DB> {
    this._dbPromise = idb.openDb("abattoir-client-db", 1, function (upgradeDb: idb.UpgradeDB) {
      switch (upgradeDb.oldVersion) {
        case 0:
          /*
          The current-batch-OS (OS is for Object Store) is intended to only hold 0 or 1 Batch instance. 
          Another OS will be needed if we want to store older batches.
          */
          upgradeDb.createObjectStore("current-batch-OS");
      }
    });
    return this._dbPromise;
  }

  setCurrentBatch(batch: Batch): Promise<void> {
    return this._dbPromise.then(function (db) {
      const tx = db.transaction("current-batch-OS", "readwrite");
      const currentBatchOs = tx.objectStore("current-batch-OS");
      currentBatchOs.put(batch, 1);
    });
  }

  getCurrentBatch(): Promise<Batch> {
    return this._dbPromise.then(function (db) {
      const tx = db.transaction("current-batch-OS", "readonly");
      const store = tx.objectStore("current-batch-OS");
      return store.get(1);
    });
  }
}

export { DataManager };
