// /* eslint-disable no-console */
import { Workbox } from "workbox-window";

let wb: Workbox | null = null;

if (wb) console.log("wb already created.");

if (!wb && process.env.NODE_ENV === "production") {
  console.log("registering workbox window");
  if ("serviceWorker" in navigator) {
    wb = new Workbox(`${process.env.BASE_URL}service-worker.js`);

    wb.addEventListener("controlling", () => {
      window.location.reload();
    });

    wb.register();
  } else {
    wb = null;
  }
}

export default wb;
