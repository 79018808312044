import Vue from "vue";
import Router, { Route, NavigationGuard } from "vue-router";
import Orca from "../views/Orca.vue";
import Abattoir from "../views/Abattoir.vue";
import Settings from "../views/Settings.vue";
import store from "../store/store";
import App from "../App.vue";

Vue.use(Router);

let router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      alias: ["/index.html", "/index"],
      name: "home",
      component: Abattoir,
      //  meta: { requiresRegistration: true }
    },
    {
      path: "/orca",
      name: "orca",
      component: Orca,

    },
    {
      path: "/abattoir",
      name: "abattoir",
      component: Abattoir,

    },
    {
      path: "/settings",
      name: "settings",
      component: Settings,

    },
  ]
});

// router.beforeEach((to: Route, from: Route, next: any) => {
//   let requiresRegistration: boolean = to.matched.some(record => record.meta.requiresRegistration);
//   let isUserRegistered: boolean = store.getters["user/isUserRegistered"]();

//   if (requiresRegistration && !isUserRegistered) {
//     next("login");
//   } else if (to.name !== "location" && to.name !== "login" && !(store as any).state.user.location) {
//     next("location");
//   } else {
//     next();
//   }
// });

export default router;
